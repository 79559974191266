var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"编辑居民规约","visible":_vm.editShow,"width":"60%","z-index":12,"destroy-on-close":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.editShow=$event},"close":_vm.closeDialog}},[_c('ax-form',{ref:"formBox",attrs:{"formBuilder":_vm.formBuilder,"data-range-keys":_vm.dateRangeKeys},on:{"change":_vm.onFormChange}},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options',
          { rules: [{ required: true, message: '请选择' }] },
        ]),expression:"[\n          'options',\n          { rules: [{ required: true, message: '请选择' }] },\n        ]"}],attrs:{"options":_vm.options,"placeholder":"请选择所属网格","change-on-select":"","show-search":{ filter: _vm.filter },"allowClear":"","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666},on:{"change":_vm.onChange}})],1),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'myContent',
        {
          rules: [{ required: true, message: '正文不能为空' }],
        },
      ]),expression:"[\n        'myContent',\n        {\n          rules: [{ required: true, message: '正文不能为空' }],\n        },\n      ]"}],attrs:{"slot":"statuteContent"},slot:"statuteContent"},[_c('wangeditor',{ref:"editor",attrs:{"content":_vm.myContent},on:{"onChange":_vm.quillHtml}})],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"20px"},on:{"click":_vm.closeDialog}},[_vm._v(" 取消 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }