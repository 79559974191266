import { post, get,deletes, downFile } from 'common/request'

export default {
 //分页查询
list: args => get('/residentStatute/list', args),
//查询所有
listAll: args => get('/residentStatute/listAll', args),
//根据ID查询
getById: id => get(`/residentStatute/getById?id=${id}`),
//新增
add: args => post('/residentStatute/add', args),
//修改
update: args => post('/residentStatute/update', args),
 //根据ID删除
deleteById: id => deletes(`/residentStatute/deleteById?id=${id}`),
//根据IDS删除
ids: args => deletes(`/residentStatute/ids?ids=${args}`),
// 网格树
gridTree:args => get('/scGridManagement/getTreeList', args),
//获取网格树
getTreeList: args => get('/scGridManagement/getTreeList', args),
}
